import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1b23e2ba = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _f72d7f64 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _a3201668 = () => interopDefault(import('../pages/contactUs/index.vue' /* webpackChunkName: "pages/contactUs/index" */))
const _bad11880 = () => interopDefault(import('../pages/showroom/index.vue' /* webpackChunkName: "pages/showroom/index" */))
const _40b63334 = () => interopDefault(import('../pages/technology/index.vue' /* webpackChunkName: "pages/technology/index" */))
const _3bfeb6b4 = () => interopDefault(import('../pages/service/b2b/index.vue' /* webpackChunkName: "pages/service/b2b/index" */))
const _52ab2bc2 = () => interopDefault(import('../pages/service/finilook/index.vue' /* webpackChunkName: "pages/service/finilook/index" */))
const _4ba6895b = () => interopDefault(import('../pages/service/finiroom/index.vue' /* webpackChunkName: "pages/service/finiroom/index" */))
const _49c8c27d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/careers",
    component: _1b23e2ba,
    name: "careers"
  }, {
    path: "/contact",
    component: _f72d7f64,
    name: "contact"
  }, {
    path: "/contactUs",
    component: _a3201668,
    name: "contactUs"
  }, {
    path: "/showroom",
    component: _bad11880,
    name: "showroom"
  }, {
    path: "/technology",
    component: _40b63334,
    name: "technology"
  }, {
    path: "/service/b2b",
    component: _3bfeb6b4,
    name: "service-b2b"
  }, {
    path: "/service/finilook",
    component: _52ab2bc2,
    name: "service-finilook"
  }, {
    path: "/service/finiroom",
    component: _4ba6895b,
    name: "service-finiroom"
  }, {
    path: "/",
    component: _49c8c27d,
    name: "index"
  }, {
    path: "/service",
    redirect: "/service/b2b"
  }, {
    path: "/about",
    redirect: "/technology"
  }, {
    path: "/recruit",
    redirect: "/careers"
  }, {
    path: "/solution",
    redirect: "/service/b2b"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
