// 믹스인 객체를 생성합니다.
export default {
  data() {
    return {
      isShowModal: false,
      modalScrollTop: 0
    }
  },

  methods: {
    isIOS() {
      // iOS 디바이스인지 확인하는 함수
      // return /iPhone|iPad|iPod/i.test(navigator.userAgent)
      return !(
        navigator.userAgent.match(/iPhone|iPad|iPod/i) == null &&
        navigator.maxTouchPoints === 0
      )
    },

    openModal() {
      // 모달 열기 전 현재 스크롤 위치를 저장합니다.
      this.modalScrollTop = window.scrollY

      // 다이얼로그 열기
      this.isShowModal = true

      document.documentElement.classList.add('overflow-y-hidden')

      // 스크롤 비활성화 (iOS 디바이스인 경우에만 실행)
      if (this.isIOS()) {
        const appWrapElement = document.querySelector('.v-application--wrap')

        if (appWrapElement) {
          appWrapElement.style.top = `-${this.modalScrollTop}px`
          appWrapElement.classList.add('modal-open')
        }
      }
    },

    closeModal() {
      // 다이얼로그 닫기
      this.isShowModal = false

      document.documentElement.classList.remove('overflow-y-hidden')

      // 스크롤 활성화 (iOS 디바이스인 경우에만 실행)
      if (this.isIOS()) {
        const appWrapElement = document.querySelector('.v-application--wrap')

        appWrapElement.classList.remove('modal-open')

        // 이전 위치로 스크롤 이동
        window.scrollTo(0, this.modalScrollTop)

        // 이전 위치로 이동한 후 top 속성 초기화
        if (appWrapElement) {
          appWrapElement.style.top = ''
        }
      }
    }
  }
}
