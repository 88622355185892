
export default {
  name: 'DefaultLayout',

  data () {
    return {
      langType: ''
    }
  },

  mounted () {
    this.setEn()

    this.resizeHeight();
    // 현재 브라우저 노출
    document.documentElement.setAttribute("data-useragent", navigator.userAgent);
  },

  methods: {
    setEn () {
      this.langType = this.$i18n.locale

      if (this.$i18n.locale === 'ko') {
        console.log('language: 🇰🇷 ' + this.$i18n.locale)
      } else if (this.$i18n.locale === 'en') {
        console.log('language: 🇺🇸 ' + this.$i18n.locale)
      } else {
        console.log('language: 🇯🇵 ' + this.$i18n.locale)
      }
    },

    resizeHeight () {
      if (this.$device.isMobile) {
        // 모바일에서는 리사이즈 미실행
        const vh = window.innerHeight * 0.01;

        document.documentElement.style.setProperty("--vh", `${vh}px`);
      } else {
        window.addEventListener("resize", () => {
          // console.log("resize");
          const vh = window.innerHeight * 0.01;

          document.documentElement.style.setProperty("--vh", `${vh}px`);
        });
      }
    }
  }
}
