import locale3e66ab0c from '../../locales/ko/index.js'
import locale6f2708d5 from '../../locales/en/index.js'
import locale20386ff2 from '../../locales/ja/index.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"ko","messages":{}},
  vueI18nLoader: true,
  locales: [{"code":"ko","iso":"ko-KR","file":"ko/index.js"},{"code":"en","iso":"en-US","file":"en/index.js"},{"code":"ja","iso":"ja-JP","file":"ja/index.js"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: true,
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ko","iso":"ko-KR","file":"ko/index.js"},{"code":"en","iso":"en-US","file":"en/index.js"},{"code":"ja","iso":"ja-JP","file":"ja/index.js"}],
  localeCodes: ["ko","en","ja"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "k",
  3: "o",
  4: "/",
  5: "i",
  6: "n",
  7: "d",
  8: "e",
  9: "x",
  10: ".",
  11: "j",
  12: "s",
  13: "\"",
  14: ":",
  15: "\"",
  16: ".",
  17: ".",
  18: "/",
  19: "l",
  20: "o",
  21: "c",
  22: "a",
  23: "l",
  24: "e",
  25: "s",
  26: "/",
  27: "k",
  28: "o",
  29: "/",
  30: "i",
  31: "n",
  32: "d",
  33: "e",
  34: "x",
  35: ".",
  36: "j",
  37: "s",
  38: "\"",
  39: ",",
  40: "\"",
  41: "e",
  42: "n",
  43: "/",
  44: "i",
  45: "n",
  46: "d",
  47: "e",
  48: "x",
  49: ".",
  50: "j",
  51: "s",
  52: "\"",
  53: ":",
  54: "\"",
  55: ".",
  56: ".",
  57: "/",
  58: "l",
  59: "o",
  60: "c",
  61: "a",
  62: "l",
  63: "e",
  64: "s",
  65: "/",
  66: "e",
  67: "n",
  68: "/",
  69: "i",
  70: "n",
  71: "d",
  72: "e",
  73: "x",
  74: ".",
  75: "j",
  76: "s",
  77: "\"",
  78: ",",
  79: "\"",
  80: "j",
  81: "a",
  82: "/",
  83: "i",
  84: "n",
  85: "d",
  86: "e",
  87: "x",
  88: ".",
  89: "j",
  90: "s",
  91: "\"",
  92: ":",
  93: "\"",
  94: ".",
  95: ".",
  96: "/",
  97: "l",
  98: "o",
  99: "c",
  100: "a",
  101: "l",
  102: "e",
  103: "s",
  104: "/",
  105: "j",
  106: "a",
  107: "/",
  108: "i",
  109: "n",
  110: "d",
  111: "e",
  112: "x",
  113: ".",
  114: "j",
  115: "s",
  116: "\"",
  117: "}",
}

export const localeMessages = {
  'ko/index.js': () => Promise.resolve(locale3e66ab0c),
  'en/index.js': () => Promise.resolve(locale6f2708d5),
  'ja/index.js': () => Promise.resolve(locale20386ff2),
}
