import AOS from 'aos'
import 'aos/dist/aos.css'

export default ({ app }) => {
  app.AOS = AOS

  // 페이지 로드 이후 AOS 초기화를 수행
  window.onNuxtReady(() => {
    app.AOS.init({
      offset: 200,
      duration: 800,
      easing: 'ease-in-out',
      once: false
    })
    app.AOS.refresh()
  })
}
