import set from 'lodash/set'

export default async (context, locale) => {
  // https://docs.google.com/spreadsheets/d/17fb_4ahIuVbYWYCs3us-ht7Z3DG5tXz-E0SqK02Wd7w/edit#gid=0
  const sheetId = '17fb_4ahIuVbYWYCs3us-ht7Z3DG5tXz-E0SqK02Wd7w'
  const sheetName = 'dictionary'
  const dictionary = {}

  const response = await context.$gsparser.parse(sheetId, sheetName)

  if (response.length) {
    // google api가 정상 연결일때는 시트의 data를 사용한다.
    response.forEach((item) => set(dictionary, item.key, item[locale]))

    // console.log('i18n: 시트 사용')
  } else {
    // google api가 비정상 연결일때는 미리 만들어둔 fallback.json data를 사용한다.
    const fallbackDictionary = require(`./${locale}/fallback.json`)
    Object.assign(dictionary, fallbackDictionary)

    // console.log('i18n: json 사용')
  }

  return Promise.resolve(dictionary)
}
