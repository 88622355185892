import axios from 'axios'

export default function ({ $axios, app, store, redirect, route, $cookies }) {
  axios.interceptors.request.use(
    (config) => {
      config.headers['Content-Type'] = 'application/json'
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const {
        config,
        response: { status, data },
      } = error
      return Promise.reject(error)
    }
  )
}
