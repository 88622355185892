export const VsModalSlot = () => import('../../components/common/modal/ModalSlot.vue' /* webpackChunkName: "components/vs-modal-slot" */).then(c => wrapFunctional(c.default || c))
export const VsChangeTheme = () => import('../../components/common/header/ChangeTheme.vue' /* webpackChunkName: "components/vs-change-theme" */).then(c => wrapFunctional(c.default || c))
export const VsHambergerMenu = () => import('../../components/common/header/HambergerMenu.vue' /* webpackChunkName: "components/vs-hamberger-menu" */).then(c => wrapFunctional(c.default || c))
export const VsHeaderMenu = () => import('../../components/common/header/HeaderMenu.vue' /* webpackChunkName: "components/vs-header-menu" */).then(c => wrapFunctional(c.default || c))
export const VsHeaderWrap = () => import('../../components/common/header/HeaderWrap.vue' /* webpackChunkName: "components/vs-header-wrap" */).then(c => wrapFunctional(c.default || c))
export const VsLanguage = () => import('../../components/common/header/Language.vue' /* webpackChunkName: "components/vs-language" */).then(c => wrapFunctional(c.default || c))
export const VsFooterAddress = () => import('../../components/common/footer/FooterAddress.vue' /* webpackChunkName: "components/vs-footer-address" */).then(c => wrapFunctional(c.default || c))
export const VsFooterMenu = () => import('../../components/common/footer/FooterMenu.vue' /* webpackChunkName: "components/vs-footer-menu" */).then(c => wrapFunctional(c.default || c))
export const VsFooterWrap = () => import('../../components/common/footer/FooterWrap.vue' /* webpackChunkName: "components/vs-footer-wrap" */).then(c => wrapFunctional(c.default || c))
export const VsLogoWrap = () => import('../../components/common/footer/LogoWrap.vue' /* webpackChunkName: "components/vs-logo-wrap" */).then(c => wrapFunctional(c.default || c))
export const MdGenerator01 = () => import('../../components/main/modal/generator-01.vue' /* webpackChunkName: "components/md-generator01" */).then(c => wrapFunctional(c.default || c))
export const MdGenerator02 = () => import('../../components/main/modal/generator-02.vue' /* webpackChunkName: "components/md-generator02" */).then(c => wrapFunctional(c.default || c))
export const MdGenerator03 = () => import('../../components/main/modal/generator-03.vue' /* webpackChunkName: "components/md-generator03" */).then(c => wrapFunctional(c.default || c))
export const MdBoardView = () => import('../../components/technology/modal/BoardView.vue' /* webpackChunkName: "components/md-board-view" */).then(c => wrapFunctional(c.default || c))
export const VsAddMedia = () => import('../../components/service/b2b/AddMedia.vue' /* webpackChunkName: "components/vs-add-media" */).then(c => wrapFunctional(c.default || c))
export const VsB2bStep = () => import('../../components/service/b2b/B2bStep.vue' /* webpackChunkName: "components/vs-b2b-step" */).then(c => wrapFunctional(c.default || c))
export const VsCreateAsset = () => import('../../components/service/b2b/CreateAsset.vue' /* webpackChunkName: "components/vs-create-asset" */).then(c => wrapFunctional(c.default || c))
export const VsMetaverseShowroom = () => import('../../components/service/b2b/MetaverseShowroom.vue' /* webpackChunkName: "components/vs-metaverse-showroom" */).then(c => wrapFunctional(c.default || c))
export const VsRemoverAi = () => import('../../components/service/b2b/RemoverAi.vue' /* webpackChunkName: "components/vs-remover-ai" */).then(c => wrapFunctional(c.default || c))
export const VsShareLink = () => import('../../components/service/b2b/ShareLink.vue' /* webpackChunkName: "components/vs-share-link" */).then(c => wrapFunctional(c.default || c))
export const VsShowroomPublishing = () => import('../../components/service/b2b/ShowroomPublishing.vue' /* webpackChunkName: "components/vs-showroom-publishing" */).then(c => wrapFunctional(c.default || c))
export const VsSwiperCreate = () => import('../../components/service/b2b/SwiperCreate.vue' /* webpackChunkName: "components/vs-swiper-create" */).then(c => wrapFunctional(c.default || c))
export const VsSwiperUpload = () => import('../../components/service/b2b/SwiperUpload.vue' /* webpackChunkName: "components/vs-swiper-upload" */).then(c => wrapFunctional(c.default || c))
export const VsVisualB2b = () => import('../../components/service/b2b/VisualB2b.vue' /* webpackChunkName: "components/vs-visual-b2b" */).then(c => wrapFunctional(c.default || c))
export const VsAppstoreDown = () => import('../../components/service/finiroom/AppstoreDown.vue' /* webpackChunkName: "components/vs-appstore-down" */).then(c => wrapFunctional(c.default || c))
export const VsEasyEdit = () => import('../../components/service/finiroom/EasyEdit.vue' /* webpackChunkName: "components/vs-easy-edit" */).then(c => wrapFunctional(c.default || c))
export const VsFollowShare = () => import('../../components/service/finiroom/FollowShare.vue' /* webpackChunkName: "components/vs-follow-share" */).then(c => wrapFunctional(c.default || c))
export const VsNowDownload = () => import('../../components/service/finiroom/NowDownload.vue' /* webpackChunkName: "components/vs-now-download" */).then(c => wrapFunctional(c.default || c))
export const VsTryIt = () => import('../../components/service/finiroom/TryIt.vue' /* webpackChunkName: "components/vs-try-it" */).then(c => wrapFunctional(c.default || c))
export const VsVisualFiniroom = () => import('../../components/service/finiroom/VisualFiniroom.vue' /* webpackChunkName: "components/vs-visual-finiroom" */).then(c => wrapFunctional(c.default || c))
export const VsRecommend = () => import('../../components/service/finilook/Recommend.vue' /* webpackChunkName: "components/vs-recommend" */).then(c => wrapFunctional(c.default || c))
export const VsScanProcess = () => import('../../components/service/finilook/ScanProcess.vue' /* webpackChunkName: "components/vs-scan-process" */).then(c => wrapFunctional(c.default || c))
export const VsServiceExperience = () => import('../../components/service/finilook/ServiceExperience.vue' /* webpackChunkName: "components/vs-service-experience" */).then(c => wrapFunctional(c.default || c))
export const VsVisualFinilook = () => import('../../components/service/finilook/VisualFinilook.vue' /* webpackChunkName: "components/vs-visual-finilook" */).then(c => wrapFunctional(c.default || c))
export const VsAniViewer = () => import('../../components/main/AniViewer.vue' /* webpackChunkName: "components/vs-ani-viewer" */).then(c => wrapFunctional(c.default || c))
export const VsAssetGenerator = () => import('../../components/main/AssetGenerator.vue' /* webpackChunkName: "components/vs-asset-generator" */).then(c => wrapFunctional(c.default || c))
export const VsConnectWrap = () => import('../../components/main/ConnectWrap.vue' /* webpackChunkName: "components/vs-connect-wrap" */).then(c => wrapFunctional(c.default || c))
export const VsContactUs = () => import('../../components/main/ContactUs.vue' /* webpackChunkName: "components/vs-contact-us" */).then(c => wrapFunctional(c.default || c))
export const VsContentsCreator = () => import('../../components/main/ContentsCreator.vue' /* webpackChunkName: "components/vs-contents-creator" */).then(c => wrapFunctional(c.default || c))
export const VsCreatorMovie = () => import('../../components/main/CreatorMovie.vue' /* webpackChunkName: "components/vs-creator-movie" */).then(c => wrapFunctional(c.default || c))
export const VsIframeViewer = () => import('../../components/main/IframeViewer.vue' /* webpackChunkName: "components/vs-iframe-viewer" */).then(c => wrapFunctional(c.default || c))
export const VsMovieWrap = () => import('../../components/main/MovieWrap.vue' /* webpackChunkName: "components/vs-movie-wrap" */).then(c => wrapFunctional(c.default || c))
export const VsOneStep = () => import('../../components/main/OneStep.vue' /* webpackChunkName: "components/vs-one-step" */).then(c => wrapFunctional(c.default || c))
export const VsVariousTemplate = () => import('../../components/main/VariousTemplate.vue' /* webpackChunkName: "components/vs-various-template" */).then(c => wrapFunctional(c.default || c))
export const VsVisualMain = () => import('../../components/main/VisualMain.vue' /* webpackChunkName: "components/vs-visual-main" */).then(c => wrapFunctional(c.default || c))
export const VsWebglViewer = () => import('../../components/main/WebglViewer.vue' /* webpackChunkName: "components/vs-webgl-viewer" */).then(c => wrapFunctional(c.default || c))
export const VsAboutArticle01 = () => import('../../components/technology/AboutArticle01.vue' /* webpackChunkName: "components/vs-about-article01" */).then(c => wrapFunctional(c.default || c))
export const VsAboutArticle02 = () => import('../../components/technology/AboutArticle02.vue' /* webpackChunkName: "components/vs-about-article02" */).then(c => wrapFunctional(c.default || c))
export const VsAiBased = () => import('../../components/technology/AiBased.vue' /* webpackChunkName: "components/vs-ai-based" */).then(c => wrapFunctional(c.default || c))
export const VsAiEditing = () => import('../../components/technology/AiEditing.vue' /* webpackChunkName: "components/vs-ai-editing" */).then(c => wrapFunctional(c.default || c))
export const VsBoardList = () => import('../../components/technology/BoardList.vue' /* webpackChunkName: "components/vs-board-list" */).then(c => wrapFunctional(c.default || c))
export const VsCoreTech = () => import('../../components/technology/CoreTech.vue' /* webpackChunkName: "components/vs-core-tech" */).then(c => wrapFunctional(c.default || c))
export const VsCorporateInquiry = () => import('../../components/technology/CorporateInquiry.vue' /* webpackChunkName: "components/vs-corporate-inquiry" */).then(c => wrapFunctional(c.default || c))
export const VsGenerativeAi = () => import('../../components/technology/GenerativeAi.vue' /* webpackChunkName: "components/vs-generative-ai" */).then(c => wrapFunctional(c.default || c))
export const VsSwiperGenerative = () => import('../../components/technology/SwiperGenerative.vue' /* webpackChunkName: "components/vs-swiper-generative" */).then(c => wrapFunctional(c.default || c))
export const VsToastView = () => import('../../components/technology/ToastView.vue' /* webpackChunkName: "components/vs-toast-view" */).then(c => wrapFunctional(c.default || c))
export const VsVisualAbout = () => import('../../components/technology/VisualAbout.vue' /* webpackChunkName: "components/vs-visual-about" */).then(c => wrapFunctional(c.default || c))
export const VsVisualTech = () => import('../../components/technology/VisualTech.vue' /* webpackChunkName: "components/vs-visual-tech" */).then(c => wrapFunctional(c.default || c))
export const VsAniProcess = () => import('../../components/careers/AniProcess.vue' /* webpackChunkName: "components/vs-ani-process" */).then(c => wrapFunctional(c.default || c))
export const VsCommonProcess = () => import('../../components/careers/CommonProcess.vue' /* webpackChunkName: "components/vs-common-process" */).then(c => wrapFunctional(c.default || c))
export const VsJobPosting = () => import('../../components/careers/JobPosting.vue' /* webpackChunkName: "components/vs-job-posting" */).then(c => wrapFunctional(c.default || c))
export const VsSwiperCompany = () => import('../../components/careers/SwiperCompany.vue' /* webpackChunkName: "components/vs-swiper-company" */).then(c => wrapFunctional(c.default || c))
export const VsSwiperWelfare = () => import('../../components/careers/SwiperWelfare.vue' /* webpackChunkName: "components/vs-swiper-welfare" */).then(c => wrapFunctional(c.default || c))
export const VsVisualCareers = () => import('../../components/careers/VisualCareers.vue' /* webpackChunkName: "components/vs-visual-careers" */).then(c => wrapFunctional(c.default || c))
export const VsWorkEnvironment = () => import('../../components/careers/WorkEnvironment.vue' /* webpackChunkName: "components/vs-work-environment" */).then(c => wrapFunctional(c.default || c))
export const VsVisualContact = () => import('../../components/contact/VisualContact.vue' /* webpackChunkName: "components/vs-visual-contact" */).then(c => wrapFunctional(c.default || c))
export const VsWayToCome = () => import('../../components/contact/WayToCome.vue' /* webpackChunkName: "components/vs-way-to-come" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
